import moment from 'moment'

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux';

/* Actions */
import * as profilesActions from '../actions/profiles.actions'
import * as habitsActions from '../actions/habits.actions'

/* Utils */
import { calculateStreak } from '../utils/habits.utils'

/* Vendor components */
import CalendarHeatmap from 'react-calendar-heatmap'

/* Components */
import Timeline from './Timeline'
import ColorPicker from './ColorPicker'
import Graph from './Graph'
import IconPicker from './IconPicker';
class Habit extends Component {
	onDoubleClick() {
		const { habit } = this.props
		this.props.updateHabit({ ...habit, editing: true })
	}
	onCancel() {
		const { habit } = this.props;
		if (habit.title === '' && habit.description === '') {
			this.props.deleteHabit(habit)
		} else {
			this.props.updateHabit({ ...habit, editing: false })

		}
	}
	onDelete() {
		const { habit } = this.props
		this.props.deleteHabit(habit)
	}

	onSubmit(event) {
		event.preventDefault()
		const { habit } = this.props
		/* Get form data */
		const title = ReactDOM.findDOMNode(this.refs.title).value
		const description = ReactDOM.findDOMNode(this.refs.description).value
		const color = ReactDOM.findDOMNode(this.refs.selectedColor).value
		const icon = ReactDOM.findDOMNode(this.refs.selectedIcon).value

		this.props.updateHabit({
			...habit,
			title: title,
			description: description,
			color: color,
			icon: icon,
			editing: false
		})
	}

	render() {
		const { habit } = this.props
		console.log(habit);
		if (!habit.editing) {
			/* Render habit */
			return (
				<div className="habit"
					key={habit.title}>
					<div className="streak" style={{backgroundColor:habit.color}}>
						<i className={` ${habit.icon}`} />
					</div>
					<div className="title"
						style={{ color: habit.color }}
						onDoubleClick={this.onDoubleClick.bind(this)}>
						{habit.title}
						{habit.description ?
							<div className="description">{habit.description}</div> : null}
					</div>
					<div className="streak">
						{calculateStreak(habit.checkmarks)}
					</div>
					<Timeline habit={habit} />
					<div className="clearfix" />
					{this.props.habits.showCalendar ?
						<Graph habit={habit} /> : null}
				</div>
			)
		} else {
			/* Edit Habit */
			return (
				<div className="habit-editing" key={habit.id} >
					<div className="my-modal color-grid">
						<div className="my-modal-body">

							<form onSubmit={this.onSubmit.bind(this)} className="d-flex flex-column w-100" style={{ gap: '20px' }}>
								<div className='d-flex justify-content-between' >
									<div>
										<label>Icon</label>
										<IconPicker
											defaultIcon={habit.icon}
											defaultColor={habit.color}
											setColor={(color) => {
												/* When color is selected, ColorPicker component
												   will call this callback function,
												   passing it the chosen color.  */
												/* console.log("Selected Color " + newColor) */
												/* I take selected color, and put it into a hidden field,
												   which I will then use in onSubmit when I save the form. */
												ReactDOM.findDOMNode(this.refs.selectedColor).value = color
											}}

											setIcon={(icon) => {

												ReactDOM.findDOMNode(this.refs.selectedIcon).value = icon
											}} />
									</div>

									<div>
										<label className='mb-3	'>Title</label>
										<input type="text"
											ref="title"
											className="title-edit w-100 p-2"
											defaultValue={habit.title}
											placeholder="New Habit" />

									</div>
									<div>
										<label className='mb-3'>Description</label>
										<input type="text"
											ref="description"
											className="description w-100 p-2"
											defaultValue={habit.description}
											placeholder="Description..." />

									</div>

								</div>
								<input defaultValue={habit.selectedColor} type="hidden"
									ref="selectedColor" />
								<input defaultValue={habit.selectedIcon} type="hidden"
									ref="selectedIcon" />


								<div className='d-flex' style={{ gap: '20px' }}>
									<button type="button "
										className="btn btn-delete"
										onClick={this.onDelete.bind(this)} style={{ width: '20%' }}>
										<i className="fa fa-trash"></i>
									</button>

									<button type="button "
										className="btn btn-submit" style={{ width: '30%' }}
										onClick={this.onCancel.bind(this)}>
										Cancel
									</button>
									<input className="btn btn-submit right w-50"
										type="submit"
										value="Save" />

								</div>

							</form>
							<div className="clearfix" />
						</div>
					</div>


					<div className="clearfix" />
				</div>
			)
		}

	}
}

/* Magic connecting component to redux */
function mapStateToProps(state) {
	return {
		profile: state.profile,
		habits: state.habits
	}
}
/* First argument allows to access state */
/* Second allows to fire actions */
export default connect(mapStateToProps, habitsActions)(Habit);
