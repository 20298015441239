import React, { Component } from 'react'
import { ICONS } from '../utils/icons'

class IconPicker extends Component {
	constructor(props) {
		super(props)
		this.state = {
			step: 0,
			selectedColor: this.props.defaultColor,
			selectedIcon: this.props.defaultIcon
		}
		console.log(this.props.defaultIcon);
	}

	render() {

		const { setColor, setIcon } = this.props;
		const colors = ["#d77c40", "#7890cb", "#EB5A46",
			"#61BD4F", "#C377E0", "#67778e",
			"#C477E0", "#67772e", "#27772e"]

		/* Render color options */
		const colorCircles = colors.map((c) => {
			return (
				<div key={c}
					className="color-swatch"
					style={{ background: c }}
					onClick={() => {
						this.setState({ step: 0, selectedColor: c })
						/* I'm passing a function setColor to this component,
					   which will be able to use the color I've picked.*/
						if (setColor !== undefined) setColor(c)
					}}>
				</div>
			)
		})

		const iconCircles = ICONS.map((icon) => {
			return (
				<div key={icon}
					className="icon"

					onClick={() => {
						this.setState({ step: 2, selectedIcon: icon })
						/* I'm passing a function setColor to this component,
					   which will be able to use the color I've picked.*/
						if (setIcon !== undefined) setIcon(icon)
					}}>
					<i className={`fa-2x ${icon}`}></i>
				</div>
			)
		})

		const { step,selectedIcon,selectedColor } = this.state;

		return (
			<div className="color-picker">
				{step === 1 &&
					<div className="my-modal icon-grid">
						<div className="my-modal-body">
							{iconCircles}
							<div className="clearfix" />
						</div>
					</div>}
				{step === 2 &&
					<div className="my-modal color-grid">
						<div className="my-modal-body">
							{colorCircles}
							<div className="clearfix" />
						</div>
					</div>}
				{step === 0 && <div className="open-picker"
					style={{ background: selectedColor }}
					onClick={() => this.setState({ step:1 })}>
					<i className={ selectedIcon || "fa fa-paint-brush"} />
				</div>
				}

			</div>
		)
	}
}

export default IconPicker




