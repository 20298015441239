import './styles/css/vendor.css';
import './styles/sass/style.scss';
import './styles/css/fontawesome-v5.15.4/all.css'
import { Provider } from "react-redux";
import store from "./store";
import Main from './components/Main';
function App() {
  return (
    <Provider store={store}>
      <Main/>
    </Provider>
  );
}

export default App;
