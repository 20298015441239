//TODO: add if export to json needed import saveAs from 'save-as'
 import { INITIAL_STATE } from "../reducers/habits.reducers"
export function createHabit() {
    return {
	type: 'CREATE_HABIT',
	payload: null
    }
}

export function updateHabit(habit) {
    return {
	type: 'UPDATE_HABIT',
	payload: habit
    }
}

export function deleteHabit(habit) {
    return {
	type: 'DELETE_HABIT',
	payload: habit
    }
}


export function updateCheckmark(checkmark) {
    return {
	    type: 'UPDATE_CHECKMARK',
	    payload: checkmark
	}
}

export function toggleCalendar() {
    return {
	type: 'TOGGLE_CALENDAR',
	payload: {}
    }
}


export function saveHabits(habits) {

    return {
	    type: 'SAVE_HABITS',
	    payload: habits
	}
    
}

export function saveHabitsBrowser(habits) {
    /* ../components/Habits.js triggers this action every time habits in state change  */
    habits.lastUpdated = new Date()
    habits.modified = false
    localStorage.setItem('habits', JSON.stringify(habits))
    console.log("[habits.actions]  Saved habits to local storage")
    return {
	type: 'SAVE_HABITS',
	payload: habits
    }
}


export function loadHabitsBrowser(oldHabits) {
    /* Triggers by Main.js, only when offline.
       Otherwise habits are loaded when fetching user.  */    

    var habits = JSON.parse(localStorage.getItem('habits'))
    console.log("Load habits from local storage")
    if (habits===null){
        habits = INITIAL_STATE;
    }
    return {
	type: 'LOAD_HABITS',
	payload: habits
    }
}



export function exportHabitsJSON(exportJSON) {
    console.log("Exporting " + JSON.stringify(exportJSON))

    /* Use magical component to save it into a file */
    var contents = JSON.stringify(exportJSON, null, 4)
    var blob = new Blob([contents], { type: 'application/json;charset=utf-8' })
    var filename = 'habits.json'
    //TODO: add if export to json needed saveAs(blob, filename)
    return {
	type: 'EXPORT_HABITS',
	payload: null
    }    
}
