
export function updateNote(note) {
    return {
	type: 'UPDATE_NOTE',
	payload: note
    }
}

export function activateNote(date) {
    return {
	type: 'ACTIVATE_NOTE',
	payload: date
    }
}

export function loadNotesBrowser() {
    /* Triggers by Main.js, only when offline.
       Otherwise notes are loaded when fetching user.  */    
    var notes = JSON.parse(localStorage.getItem('notes'))
    console.log("Load notes from local storage")
    return {
	type: 'LOAD_NOTES',
	payload: notes
    }
}

export function saveNotes(notes) {
    var data = notes
    data.lastUpdated = new Date()

    return {
	    type: 'SAVE_NOTES',
	    payload: notes
	}
}

export function saveNotesBrowser(notes) {
    notes.lastUpdated = new Date()
    localStorage.setItem('notes', JSON.stringify(notes))
    console.log("Save notes " + JSON.stringify(notes))
    console.log("[notes.actions]  Saved notes to local storage")
    return {
	type: 'SAVE_NOTES',
	payload: {}
    }
}
